<template lang="html">
	<vs-card class="shadow-none">
		<!-- div contenant la totalité de la card -->
		<div class="vx-row">
			<!-- div contenant logo + infos organization & users -->
			<div class="vx-col w-full sm:w-1/2 md:w-2/3 m-auto flex">
				<!-- Div logo organization -->
				<div class="w-1/3 hidden md:flex m-auto relative">
					<img
						:src="getImageUrl(organization)"
						class="rounded-full shadow-md m-auto object-contain"
						height="70px"
						width="70px"
						style="object-fit: contain"
					/>
				</div>

				<!-- Nom de l'organization -->
				<div v-if="app" class="w-full w-2/3 text-center md:text-left md:ml-3">
					<h4 class="title-sinao font-bold">
						<a class="title-sinao cursor-pointer" :href="app.url">{{ organization.name }}</a>
					</h4>

					<!-- Siren NAF et adresse + bouton modifier -->
					<div v-if="app" class="inline-block mt-3">
						<div v-if="app.admin_user_id == user.id && app.subscription" class="flex w-full mb-1">
							<p>
								{{ $t("Abonnement") }} :
								<!--
								<strong v-if="app.subscription">{{ app.subscription.plan_name }}</strong>
								<strong v-else>Version d'essai</strong>
								-->
							</p>
							<div class="relative ml-2">
								<vs-button
									v-if="
										app.subscription.plan.nickname == 'trial' ||
										app.subscription.plan.nickname == 'freemium'
									"
									class="detail text-xs"
									type="flat"
									style="padding: 1px 5px"
									icon-pack="feather"
									icon="icon-star"
									:disabled="disabled"
									@click="subscribtionClick(app.url)"
								>
									{{ $t("S'abonner") }}
								</vs-button>

								<vs-button
									v-else
									class="detail text-xs"
									type="flat"
									style="padding: 1px 5px"
									icon-pack="feather"
									icon="icon-star"
									:disabled="disabled"
									@click="subscribtionClick(app.url)"
								>
									{{ app.subscription.plan.nickname.toUpperCase() }}
								</vs-button>
							</div>
						</div>
						<div class="flex w-full">
							<p>
								{{ $t("N° SIREN") }} :
								<strong v-if="organization.national_id">{{ organization.national_id }}</strong>
								<strong v-else>{{ $t("Non renseigné") }}</strong>
							</p>

							<div class="relative ml-2">
								<vs-button
									class="detail text-xs"
									type="flat"
									style="padding: 1px 5px"
									icon-pack="feather"
									icon="icon-plus"
									:disabled="disabled"
									@click="switchDetails()"
								>
									{{ $t("Détails") }}
								</vs-button>
							</div>
							<div v-show="details" v-if="app.admin_user_id == user.id" class="relative ml-5">
								<vs-button
									color="var(--primary)"
									class="detail text-xs"
									type="flat"
									style="padding: 1px 5px"
									icon-pack="feather"
									icon="icon-edit"
									:disabled="disabled"
									@click="adresseClick(app.url)"
								>
									{{ $t("Modifier") }}
								</vs-button>
							</div>
						</div>
						<div v-show="details" class="text-left">
							<p>
								{{ $t("Code NAF") }} :
								<strong v-if="organization.code_naf">{{ organization.code_naf }}</strong>
								<strong v-else>{{ $t("Non renseigné") }}</strong>
							</p>
							<p>
								{{ $t("Adresse") }} :
								<strong>{{ organization.headquarters.place.formatted_address }}</strong>
							</p>
						</div>

						<div v-if="app.admin_user_id == user.id" class="text-left mt-1">
							<!-- dernier acces -->
							<p v-if="dateToUTC(app.last_access)">
								{{ $t("Dernier accès le") }}
								{{ dateToUTC(app.last_access) }}
							</p>
						</div>

						<!-- liste des utilisateurs -->
						<div class="vs-row m-auto mt-2 flex w-full mb-5 justify-center md:justify-start">
							<div class="flex">
								<img
									v-for="emp in policies.slice(0, 5)"
									:key="emp.id"
									:src="getImageUrl(emp.user)"
									width="44px"
									height="44px"
									class="avatar"
									:class="{ 'avatar-admin': hoverable && !disabled }"
								/>

								<!-- ajout d'utilisateur -->
								<vs-tooltip
									v-if="
										!app.subscription.plan.limits.users ||
										app.policies.length + app.invites.length < app.subscription.plan.limits.users
									"
									class="tooltip"
									:text="$t('Ajouter un utilisateur')"
								>
									<router-link
										v-if="app.admin_user_id == user.id"
										tag="button"
										class="btn-admin"
										:to="{ name: 'Invite', params: { appId: app.id } }"
										:tearia-labelxt="$t('Ajouter un utilisateur')"
										:disabled="disabled"
									>
										<vs-icon icon-pack="feather" icon="icon-user-plus" />
									</router-link>
									<!-- second child elemnt... just to make vs-tooltip work, don't ask me -->
									<span></span>
								</vs-tooltip>
								<vs-tooltip class="tooltip" :text="$t('Gérer les utilisateurs')">
									<!-- gestion de l'app -->
									<button
										v-if="app.admin_user_id == user.id"
										class="btn-admin"
										:aria-label="$t('Gérer les utilisateurs')"
										:disabled="disabled"
										@click="membersClick()"
									>
										<vs-icon icon-pack="feather" icon="icon-settings" />
									</button>
									<!-- second child elemnt... just to make vs-tooltip work, don't ask me -->
									<span></span>
								</vs-tooltip>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Div contenant abonnement, tableau de bord et dernier acces -->
			<div class="w-full flex-1 flex text-center sm:mr-10">
				<div class="flex items-center ml-auto mr-auto sm:mr-0">
					<!-- bouton acces tableau de bord -->
					<s-button
						class="m-auto"
						icon="icon-arrow-right"
						icon-after="true"
						:disabled="disabled"
						@click="redirectTo(app.url)"
					>
						{{ $t("Tableau de bord") }}
					</s-button>
				</div>
			</div>
		</div>
	</vs-card>
</template>

<script lang="js">
	// pour créer une date UTC europe
	var moment = require('moment-timezone');

	import { mapState, mapGetters } from 'vuex';

	export default {
		name: 'OrganizationItem',
		props: {
			app: Object,
			disabled: Boolean
		},
		data () {
			return {
				details: false,
				organization: null,
				policies: null,
				invites: null
			};
		},
		computed: {
			...mapState(["user"]),
			...mapGetters(["getImageUrl"]),
			hoverable() {
				return this.app.admin_user_id == this.user.id;
			},
			subscription() {
				return this.app.subscription;
			}
		},
		created() {
			const { organization, policies, invites, admin } = this.app;

			this.organization = organization;
			this.policies = policies;
			this.invites = invites;

			this.policies.push({
				user_id: admin.id,
				policy_profile_id: 6,
				app_id: this.appId,
				user: admin
			});
		},
		methods: {
			// retourne un string du dernier accès à l'app (de la forme "xx/xx/xxxx à xxhxx" timeZone : Europe)
			dateToUTC: function(date) {
				if (date != null) {
					// si on a une date, on la met au format UTC+1
					var newDate = moment.tz(date, 'Europe/Berlin').format();

					// on découpe pour afficher sous la forme : XX/XX/XXXX à XXhXX
					return `${ newDate.substr(8, 2) }/${ newDate.substr(5, 2) }/${ newDate.substr(0, 4) } à ${ newDate.substr(11, 5) }`;
				}
				else {
					// on retourne false, ce qui annulera l'affichage de la date grâce au v-if de la balise <p>
					return false;
				}
			},
			// permet d'afficher les détails (adresse, codeNaf) pour l'entreprise voulue
			switchDetails: function() {
				this.details = !this.details;
			},
			membersClick() {
				if (this.app.admin_user_id == this.user.id && !this.disabled) {
					this.$router.push({ name: 'Members', params: { appId: this.app.id } });
				}
			},
			// permet la redirection vers les abonnements du compte de l'utilisateur
			subscribtionClick: function(path) {
				window.location.href = `${ path }settings/subscription/select/`;
			},
			// permet la redirection vers le formulaire de mise a jour des information de l'entreprise selectionnée
			adresseClick(path) {
				window.location.href = `${ path }settings/organization/main/`;
			},
			// permet la redirection vers l'app principale/tableau de bord
			redirectTo: function(lien) {
				window.location.href = lien;
			}
		}
	};
</script>

<style scoped lang="scss">
	.btn-admin {
		background-color: var(--white);
		border: 1px solid var(--border);
		color: var(--black);

		position: relative;
		margin-right: -15px;
		cursor: pointer;
		height: 44px;
		width: 44px;
		border-radius: 22px;
		transition: 150ms ease-in-out;
		transition-property: transform background-color;
	}
	.avatar-admin:hover,
	.btn-admin:hover,
	.avatar-admin:focus,
	.btn-admin:focus {
		background-color: var(--border);

		transform: translateY(-5px);
		outline: none;
	}

	.avatar {
		position: relative;
		margin-right: -15px;
		border: 2px solid var(--white);
		border-radius: 50%;
		overflow: hidden;
		width: 44px;
		height: 44px;
		text-align: center;
		transition: transform 150ms ease-in-out;
	}
	.avatar-admin:hover {
		cursor: pointer;
	}
	.tooltip {
		padding-right: 15px;
		margin-right: -15px;
		border-radius: 50%;
	}
</style>
