<template>
	<div>
		<!-- en-tête avec "mes apps + bouton ajout nouvelle entreprise" -->
		<div class="vx-row mt-3 mb-6 flex justify-between">
			<div class="vx-col w-full sm:w-1/2 flex justify-center sm:justify-between">
				<h2 class="title-sinao self-center">{{ $t("Mes entreprises") }}</h2>
			</div>

			<div v-if="!errorAppsMessage" class="w-fit vx-col flex justify-center sm:justify-end">
				<s-button
					icon="icon-plus"
					type="border"
					class="sm:m-base float-none sm:float-right"
					:disabled="loading"
					@click="showNewAppForm"
				>
					{{ $t("Ajouter une entreprise") }}
				</s-button>
			</div>
		</div>

		<!-- Card d'ajout d'une nouvelle entreprise -->
		<transition name="pop-appear">
			<vs-card v-if="newAppForm" class="pop shadow-none">
				<div class="vx-row mt-3">
					<div class="vx-col w-full sm:w-1/2 m-auto">
						<div class="w-full flex m-auto text-center">
							<div class="vx-col w-full m-auto text-center align-vertical w-full">
								<h4>{{ $t("Ajout d'une nouvelle entreprise") }}</h4>
								<vs-input
									ref="appName"
									v-model="newAppName"
									v-validate="{
										required: true,
										min: 4,
										regex: new RegExp('^[A-zÀ-ÿ0-9 -]+$', 'ui')
									}"
									name="appName"
									:placeholder="$t('Nom de l entreprise')"
									color="var(--primary)"
									data-vv-validate-on="blur"
									label-placeholder="Nom de l'entreprise"
									class="m-auto mt-5"
									left
								/>

								<span v-show="errors.has('appName')" class="text-danger text-sm"
									>{{ errors.first("appName") }} <br
								/></span>
								<br />
								<select id="" v-model="sizeCompany" name="" class="vs-input select-input">
									<option value="" disabled selected>
										{{ $t("Choisir votre taille d’entreprise") }}
									</option>
									<option v-for="size in sizes" :key="size.value" :value="size.value">
										{{ size.text }}
									</option>
								</select>
								<div class="mt-5">
									<s-button class="ml-3" type="border" @click="toggleCards">
										{{ $t("Annuler") }}
									</s-button>
									<s-button
										ref="submit"
										class="ml-3 vs-con-loading__container"
										:disabled="
											!(
												newAppName.length >= 4 &&
												['solo', 'small', 'medium'].includes(sizeCompany)
											) || disabled
										"
										@click="createApp"
									>
										{{ $t("Valider") }}
									</s-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</vs-card>
		</transition>

		<!-- Filtrer Liste des entreprises -->
		<input
			v-if="apps && apps.length >= 8"
			v-model="search"
			type="text"
			placeholder="Rechercher une entreprise"
			class="vs-input mt-5 search-input"
			:class="{ veiled: disableCards }"
		/>

		<div class="veilable" :class="{ veiled: disableCards }">
			<vs-card v-if="apps && apps.length === 0" class="text-center veilable">
				<h4 style="padding-bottom: 30px">{{ $t("Vous ne disposez encore d'aucune entreprise") }}</h4>
				<h5 class="mt-3" v-html="$t('Cliquez sur ...')"></h5>
				<p class="mt-3">{{ $t("Si vous ne retrouvez pas vos données, veuillez recharger la page.") }}</p>
			</vs-card>

			<!-- Sinon on parcourt la liste des apps -->
			<div v-show="loading" ref="loader" class="centerx vs-con-loading__container" style="height: 100px">
				{{ $t("Chargement...") }}
			</div>

			<template v-for="app in filteredApps">
				<OrganizationItem
					v-if="apps.length < 8"
					:key="app.id"
					:app="app"
					:disabled="disableCards"
					class="w-full"
				/>

				<OrganizationItemSmall
					v-if="apps.length >= 8"
					:key="app.id"
					:app="app"
					:disabled="disableCards"
					class="w-full"
				/>
			</template>
		</div>
	</div>
</template>

<script>
	import { Validator } from "vee-validate";
	import OrganizationItem from "../components/OrganizationItem";
	import OrganizationItemSmall from "../components/OrganizationItemSmall";
	import { getToken } from "sinao-corejs";
	import i18n from "../i18n";

	const dict = {
		custom: {
			appName: {
				required: () => i18n.t("Veuillez entrer le nom de l'entreprise"),
				min: () => i18n.t("Veuillez entrer 4 caractères minimum"),
				alpha_dash: () => i18n.t("Veuillez entrer que des caractères alphanumériques")
			}
		}
	};

	Validator.localize("fr", dict);

	export default {
		components: {
			OrganizationItem,
			OrganizationItemSmall
		},
		data() {
			return {
				apps: null,
				errorAppsMessage: null,
				newAppForm: false,
				newAppName: "",
				sizeCompany: "",
				disableCards: false,
				disabled: false,
				types: ["default", "point", "radius", "corners", "border", "sound", "material"],
				loading: false,
				search: "",
				sizes: [
					{ text: i18n.t("Vous travaillez seul"), value: "solo" },
					{ text: i18n.t("1 à 5 salariés"), value: "small" },
					{ text: i18n.t("6 salariés et plus"), value: "medium" }
				]
			};
		},
		computed: {
			filteredApps() {
				const searchTerm = (this.search ?? "").toLowerCase();

				if (searchTerm === "" || searchTerm === null || searchTerm === undefined) return this.apps;

				return [...this.apps].filter(app => {
					const organizationName = (app.organization.name ?? "").toLowerCase();
					const nationalId = (app.organization.national_id ?? "").toLowerCase();
					return organizationName.includes(searchTerm) || nationalId.includes(searchTerm);
				});
			}
		},
		async mounted() {
			try {
				this.$vs.loading({
					type: "default",
					background: "var(--white)",
					color: "var(--black)",
					// text: "Chargement...",
					container: this.$refs.loader,
					scale: 0.5
				});

				this.loading = true;

				this.apps = await this.$store.dispatch("getApps");
			} catch (error) {
				console.log(error);
				this.errorAppsMessage = i18n.t(
					"Une erreur est survenue lors du chargement des entreprises, veuillez revenir plus tard."
				);
			} finally {
				this.loading = false;
				this.$vs.loading.close(this.$refs.loader);
			}
		},
		methods: {
			showNewAppForm: function () {
				// Disable all cards
				this.toggleCards();
			},
			toggleCards: function () {
				this.disableCards = !this.disableCards;
				this.newAppForm = !this.newAppForm;

				this.$nextTick(function () {
					if (this.$refs["appName"]) {
						this.$refs["appName"].focusInput();
					}

					this.newAppName = "";
				});
			},
			async createApp() {
				const isFormValid = await this.$validator.validateAll();

				if (isFormValid) {
					this.disabled = true;

					this.$vs.loading({
						type: "default",
						color: "var(--white)",
						background: "var(--primary)",
						container: this.$refs.submit.$el,
						scale: 0.5
					});

					try {
						await this.$store
							.dispatch("createApp", {
								organization_name: this.newAppName,
								organization_size: this.sizeCompany
							})
							.then(async () => {
								this.apps = await this.$store.dispatch("getApps");
							});

						await this.$store.dispatch("updateAppSettings", {
							appId: this.apps.slice(-1)[0].id,
							token: getToken(),
							data: {
								knowledge_marketing: "account"
							}
						});

						this.toggleCards();
					} catch (error) {
						console.log(error);
					}

					this.disabled = false;
					this.$vs.loading.close(this.$refs.submit.$el);
				}
			}
		}
	};
</script>

<style>
	.vs-con-loading__container.loading_container {
		height: 40px;
		overflow: visible;
		filter: blur(0.5px);
		width: 0;
		right: 20px;
		z-index: -1;
	}
	.fade {
		transition: opacity ease-in-out 100ms;
	}
	.faded {
		opacity: 0;
	}

	.veiled {
		opacity: 0.33;
		filter: blur(2px);
	}

	.veilable {
		transition: 300ms ease-in-out;
		transition-property: opacity, filter;
	}

	div.vs-card--content {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.pop {
		transition: 300ms ease-in-out;
		transition-property: max-height, opacity;
		height: auto;
		overflow: hidden;
	}
	.pop.pop-appear-enter {
		max-height: 0em;
		opacity: 0;
	}
	.pop.pop-appear-enter-to {
		max-height: 15em;
		opacity: 1;
	}
	.pop.pop-appear-leave {
		max-height: 15em;
		opacity: 1;
	}
	.pop.pop-appear-leave-to {
		max-height: 0em;
		opacity: 0;
	}

	.select-input,
	.search-input {
		border-radius: 6px;
	}

	.search-input:focus-within {
		border: 1px solid var(--black);
	}

	.search-input {
		width: 100%;
		margin-bottom: 20px;
		border: 1px solid transparent;
		padding: 12px;
	}

	.select-input {
		height: 32px;
	}

	.select-input {
		width: 220px;
		font-size: 12px;
	}

	.vs-con-input-label {
		width: 220px;
	}

	.vs-input--input.large {
		font-size: 14px !important;
	}

	.vs-con-input > span {
		text-align: left !important;
	}

	.vs-con-loading__container {
		border-radius: 8px;
		margin-bottom: 20px;
	}
</style>
